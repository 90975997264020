import React from 'react';

import { navigate } from 'gatsby';

const CreditCouple = () => {
  React.useEffect(() => {
    navigate('/');
  }, []);

  return <></>;
};

export default CreditCouple;
